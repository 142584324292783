import { action, computed, observable } from 'mobx';

export class ErrorMsg {
  nextCursor = '';
  @observable accessor message = '';

  @computed
  get currentMessage(): string {
    return this.message;
  }

  @action
  setMessage = (message: string) => {
    this.message = message;
  };

  @action
  clearMessage = () => {
    this.message = '';
  };
}

export default new ErrorMsg();
