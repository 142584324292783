import { isProd } from '@/utils/context';
import * as Sentry from '@sentry/react';

const SENTRY_ENVIRONMENTS = ['develop', 'production'];

if (SENTRY_ENVIRONMENTS.includes(import.meta.env.VITE_APP_PAYMENT_ENV || '')) {
  Sentry.init({
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    environment: import.meta.env.VITE_APP_PAYMENT_ENV,
    release: import.meta.env.VITE_APP_PAYMENT_TAG,
    // tunnel: '/sentry',
    integrations: [Sentry.browserTracingIntegration()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: isProd ? 0.1 : 1.0,
    ignoreErrors: ['Response returned an error code'],
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i
    ]
  });
}
