import React from 'react';
import errorMsg, { ErrorMsg } from './modules/error';
import order, { Order } from './modules/order';

export type RootStore = {
  errorMsg: ErrorMsg;
  order: Order;
};

export const store: RootStore = {
  errorMsg,
  order
};

const StoreContext = React.createContext<RootStore>(store);

export const StoreProvider = StoreContext.Provider;

export const useStore = () => React.useContext(StoreContext);

export { errorMsg, order };
