import {
  useImperativeHandle,
  forwardRef,
  useEffect,
  useState,
  useCallback
} from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'clsx';
import { useIMask } from 'react-imask';
import { Input } from '@linktivity/link-ui';
import { cardCVCMaskOptions, checkCardCVC } from '@/utils/card';
import { ICardCvcProps, ICardCvcRef } from './CardCvc.types';
import styles from './cvc.module.css';

const CardCvc = forwardRef<ICardCvcRef, ICardCvcProps>(
  ({ cardType, setCardCvv, onValidChange }, ref) => {
    const { t } = useTranslation();
    const [error, setError] = useState('');
    const { ref: cardCvcRef, unmaskedValue: cardCvc } = useIMask(
      cardCVCMaskOptions,
      {
        onAccept: () => {
          setError('');
        }
      }
    );

    const validateCardCvc = useCallback(() => {
      const valid = checkCardCVC(cardCvc, cardType);
      if (valid) {
        setError('');
      } else {
        setError(t('error.invalidCardCvc'));
      }
      return valid;
    }, [cardCvc, cardType, t]);

    useImperativeHandle(ref, () => ({
      validateCardCvc
    }));

    useEffect(() => {
      setCardCvv?.(cardCvc);
    }, [cardCvc, setCardCvv]);

    useEffect(() => {
      onValidChange?.(!error);
    }, [onValidChange, error]);

    return (
      <div className={styles.cvc}>
        <div
          className={cls(styles.cvcInner, {
            [styles.showMessage]: !!error
          })}
        >
          {/* <label className={styles.cvcName}>{t('card.cardCvc')}</label> */}
          <Input
            inputMode="numeric"
            autoComplete="cc-csc"
            name="cardCvc"
            className={styles.cvcInput}
            placeholder="CVC"
            ref={cardCvcRef}
            onBlur={validateCardCvc}
            invalid={!!error}
          />
        </div>
        {error && (
          <div className={styles.message}>
            <span className={styles.messageText}>{error}</span>
          </div>
        )}
      </div>
    );
  }
);

CardCvc.displayName = 'CardCvc';

export default CardCvc;
