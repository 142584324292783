import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { BaseLayout } from '@/layouts';
import Loader from './Loader';

const Account = lazy(() => import('@/views/Account'));
const Faq = lazy(() => import('@/views/Faq'));
const NotFound = lazy(() => import('@/views/NotFound'));
const Order = lazy(() => import('@/views/Order'));

const AppRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<BaseLayout />}>
          <Route index element={<NotFound />} />
          <Route path="account" element={<Account />} />
          <Route path=":secret/:orderId" element={<Order />} />
          <Route path="faq" element={<Faq />} />
          <Route path="404" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
