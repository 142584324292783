import cls from 'clsx';
import { ErrorIcon, SuccessIcon, WarningIcon } from '@/assets/icons';
import styles from './status.module.css';

export const StatusType = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning'
} as const;

export type StatusType = (typeof StatusType)[keyof typeof StatusType];
interface IStatusProps {
  type: StatusType;
}
const Status: React.FC<IStatusProps> = ({ type }) => {
  return (
    <div className={cls(styles.status, styles[type])}>
      {type === StatusType.ERROR && <ErrorIcon className={styles.icon} />}
      {type === StatusType.SUCCESS && <SuccessIcon className={styles.icon} />}
      {type === StatusType.WARNING && <WarningIcon className={styles.icon} />}
    </div>
  );
};

export default Status;
