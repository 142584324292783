import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'clsx';
import { ExternalLink } from '@/components';
import logo from '@/assets/logo.png';
import styles from './footer.module.css';

export interface IFooterProps {
  className?: string;
}

const Footer: React.FC<IFooterProps> = ({ className = '' }) => {
  const { t } = useTranslation();

  return (
    <footer className={cls(styles.footer, className)}>
      <div className={styles.inner}>
        <div className={styles.info}>
          <div className={styles.power}>
            <span>Powered by</span>
            <picture className={styles.powerPicture}>
              <img src={logo} className={styles.powerImage} />
            </picture>
          </div>
          <div className={styles.terms}>
            <ExternalLink
              href={`${location.origin}/faq`}
              className={styles.infoLink}
            >
              {t('footer.faq')}
            </ExternalLink>
            <ExternalLink
              href="https://www.triplabo.jp/privacy"
              className={styles.infoLink}
            >
              {t('footer.privacy')}
            </ExternalLink>
            <ExternalLink
              href="https://www.triplabo.jp/terms"
              className={styles.infoLink}
            >
              {t('footer.terms')}
            </ExternalLink>
          </div>
        </div>
        <div className={styles.copyright}>
          {`© ${new Date().getFullYear()} Copyright LINKTIVITY Inc. All Rights Reserved.`}
        </div>
      </div>
    </footer>
  );
};

export default memo(Footer);
