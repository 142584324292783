import { IMask } from 'react-imask';
import { CardNetwork } from '@/services/const';
import {
  AmexIcon,
  DinersIcon,
  JcbIcon,
  MastercardIcon,
  UnionpayIcon,
  VisaIcon
} from '@/assets/icons';

export const typeToIcon: Record<
  string,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  [CardNetwork.Amex]: AmexIcon,
  [CardNetwork.Diners]: DinersIcon,
  [CardNetwork.Jcb]: JcbIcon,
  [CardNetwork.Mastercard]: MastercardIcon,
  [CardNetwork.Unionpay]: UnionpayIcon,
  [CardNetwork.Visa]: VisaIcon
};

export const CARD_REGEX = {
  [CardNetwork.Amex]: '^3[47]\\d{0,13}',
  [CardNetwork.Diners]: '^3(?:0([0-5]|9)|[68]\\d?)\\d{0,11}',
  [CardNetwork.Jcb]: '^(?:2131|1800|35\\d{0,3})\\d{0,11}',
  [CardNetwork.Mastercard]:
    '^(5[1-5]\\d{0,2}|22[2-9]\\d{0,1}|2[3-7]\\d{0,2})\\d{0,12}',
  [CardNetwork.Unionpay]: '^62\\d{0,14}',
  [CardNetwork.Visa]: '^4\\d{0,15}'
};

export interface CardMask {
  mask: string;
  regex: string;
  cardType: CardNetwork;
}

export const cardNumberMaskOptions = {
  mask: [
    {
      mask: '0000 0000 0000 0000',
      regex: CARD_REGEX[CardNetwork.Visa],
      cardType: CardNetwork.Visa
    },
    {
      mask: '0000 0000 0000 0000',
      regex: CARD_REGEX[CardNetwork.Mastercard],
      cardType: CardNetwork.Mastercard
    },
    {
      mask: '0000 000000 00000',
      regex: CARD_REGEX[CardNetwork.Amex],
      cardType: CardNetwork.Amex
    },
    {
      mask: '0000 0000 0000 0000',
      regex: CARD_REGEX[CardNetwork.Jcb],
      cardType: CardNetwork.Jcb
    },
    {
      mask: '0000 000000 0000',
      regex: CARD_REGEX[CardNetwork.Diners],
      cardType: CardNetwork.Diners
    },

    {
      mask: '0000 0000 0000 0000',
      regex: CARD_REGEX[CardNetwork.Unionpay],
      cardType: CardNetwork.Unionpay
    },
    {
      mask: '0000 0000 0000 0000',
      cardType: CardNetwork.CardNetworkUnspecified
    }
  ],
  dispatch: (appended: string, dynamicMasked: IMask.MaskedDynamic) => {
    const number = (dynamicMasked.value + appended).replace(/\D/g, '');
    return dynamicMasked.compiledMasks.find(m => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const regex = new RegExp(m.regex);
      return regex.test(number);
    });
  }
};

export function checkCardNumber(cardType: CardNetwork, cardNumber: string) {
  let validCardNumber = true;
  switch (cardType) {
    case CardNetwork.Visa: {
      const regex = new RegExp('^4[0-9]{12}(?:[0-9]{3})?');
      validCardNumber = regex.test(cardNumber);
      break;
    }
    case CardNetwork.Mastercard: {
      const regex = new RegExp(
        '^(5[1-5]\\d{0,2}|22[2-9]\\d{1}|2[3-7]\\d{2})\\d{12}'
      );
      validCardNumber = regex.test(cardNumber);
      break;
    }
    case CardNetwork.Amex: {
      const regex = new RegExp('^3[47]\\d{13}');
      validCardNumber = regex.test(cardNumber);
      break;
    }
    case CardNetwork.Jcb: {
      const regex = new RegExp('^(?:2131|1800|35\\d{3})\\d{11}');
      validCardNumber = regex.test(cardNumber);
      break;
    }
    case CardNetwork.Diners: {
      const regex = new RegExp('^3(?:0([0-5]|9)|[68]\\d)\\d{11}');
      validCardNumber = regex.test(cardNumber);
      break;
    }
    case CardNetwork.Unionpay: {
      const regex = new RegExp('^62\\d{14}');
      validCardNumber = regex.test(cardNumber);
      break;
    }
    default: {
      validCardNumber = false;
    }
  }

  return validCardNumber;
}

export const cardExpiryMaskOptions = {
  mask: 'MM{/}YY',
  blocks: {
    YY: {
      mask: '00'
    },
    MM: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 12
    }
  }
};

export function checkCardExpiry(cardExpiry: string) {
  let invalidCardExpiry = false;
  let pastCardExpiry = false;
  if (cardExpiry.length !== 5) {
    invalidCardExpiry = true;
  }
  const [mm, yy] = cardExpiry.split('/');
  const now = new Date();
  const currentYear = Number(now.getFullYear().toString().substring(2));
  const currentMonth = now.getMonth() + 1;
  if (currentYear > Number(yy)) {
    pastCardExpiry = true;
  } else if (currentYear === Number(yy)) {
    // compare month
    if (currentMonth > Number(mm)) {
      pastCardExpiry = true;
    }
  }

  return {
    invalidCardExpiry,
    pastCardExpiry
  };
}

export function checkCardCVC(cardCvc: string, cardType?: CardNetwork) {
  let cvcLen = 3;
  if (cardType === CardNetwork.Amex) {
    cvcLen = 4;
  }
  if (cardCvc.length === cvcLen) {
    return true;
  } else {
    return false;
  }
}

export const cardCVCMaskOptions = {
  mask: '0000'
};

export const cardNameRegex = /^[a-zA-Z ]*$/;

export function checkCardName(cardName: string) {
  return cardNameRegex.test(cardName);
}

export function formatExpiry(expiry?: string) {
  if (expiry) {
    const mm = expiry.slice(2, 4);
    const yy = expiry.slice(0, 2);
    return `${mm}/${yy}`;
  } else {
    return '';
  }
}

export function getCardType(cardNo?: string): CardNetwork {
  if (cardNo) {
    const cardPrefix = cardNo.slice(0, 6);
    const cardType = Object.keys(CARD_REGEX).find(key => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const regex = new RegExp(CARD_REGEX[key]);
      return regex.test(cardPrefix);
    }) as CardNetwork;
    return cardType || CardNetwork.CardNetworkUnspecified;
  } else {
    return CardNetwork.CardNetworkUnspecified;
  }
}
