import { action, computed, observable } from 'mobx';
import { orderApi } from '@/services';
import { Order as OrderType, GetOrderReq } from '@/services/types';
export class Order {
  @observable accessor order: OrderType = {};

  @action
  getOrder({ orderId, secret, accessToken }: GetOrderReq) {
    return orderApi
      .getOrder({
        body: {
          orderId,
          secret,
          accessToken
        }
      })
      .then(order => {
        if (order) {
          this.setOrder(order);
        }
      });
  }

  @action
  setOrder = (order: OrderType) => {
    this.order = order;
  };

  @computed
  get id() {
    return this.order.id || '';
  }
  @computed
  get loaded() {
    return !!this.order.id;
  }
  @computed
  get status() {
    return this.order.status;
  }
  @computed
  get productName() {
    return this.order.productName;
  }
  @computed
  get availableCards() {
    return this.order.availableCards || [];
  }
  @computed
  get deadline() {
    return this.order.deadline;
  }
  @computed
  get shopId() {
    return this.order.shopId;
  }
  @computed
  get total() {
    return this.order.total;
  }
  @computed
  get email() {
    return this.order.email;
  }

  @computed
  get items() {
    return this.order.items || [];
  }
  @computed
  get productThumbnailUrl() {
    return this.order.productThumbnailUrl;
  }
  @computed
  get merchantInfo() {
    return this.order.merchantInfo;
  }
  @computed
  get note() {
    return this.order.note;
  }

  @computed
  get returnUrl() {
    return this.order.returnUrl;
  }
  @computed
  get errorMsg() {
    return this.order.errorMsg;
  }
  @computed
  get cardNetworks() {
    return this.order.cardNetworks;
  }
}

export default new Order();
