import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { ExternalLink, Status, StatusType } from '@/components';
import { Dialog, Button } from '@linktivity/link-ui';
import { useStore } from '@/stores';
import styles from './error.module.css';

const ErrorMsg: React.FC = observer(() => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const { errorMsg } = useStore();

  useEffect(() => {
    if (errorMsg.currentMessage) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [errorMsg.currentMessage]);

  const close = useCallback(() => {
    setVisible(false);
    // after render clear message
    window.setTimeout(() => {
      errorMsg.clearMessage();
    }, 300);
  }, [errorMsg]);

  return (
    <Dialog
      visible={visible}
      onClose={close}
      className={styles.dialog}
      title={<Status type={StatusType.ERROR} />}
    >
      <p className={styles.errorMsg}>{errorMsg.currentMessage}</p>
      <div className={styles.errorAction}>
        <ExternalLink
          className={styles.errorActionButton}
          href={`${location.origin}/faq`}
        >
          {t('footer.faq')}
        </ExternalLink>
        <Button className={styles.errorClose} onClick={close}>
          {t('common.close')}
        </Button>
      </div>
    </Dialog>
  );
});

export default ErrorMsg;
