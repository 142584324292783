import { DEFAULT_LOCALE } from '@/constants/locale';

export function getValidLocale(lang: string) {
  if (lang.startsWith('ja')) {
    return 'ja';
  }
  if (lang.startsWith('ko')) {
    return 'ko';
  }
  if (lang.startsWith('en')) {
    return 'en';
  }
  if (lang.startsWith('zh-Hant') || lang.startsWith('zt')) {
    return 'zt';
  }
  if (lang.startsWith('zh-Hans') || lang.startsWith('zh')) {
    return 'zh';
  }

  return DEFAULT_LOCALE;
}
