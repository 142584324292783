import React from 'react';

export interface ILinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href?: string;
}

const ExternalLink: React.FC<ILinkProps> = ({
  href,
  className = '',
  children
}) => {
  return (
    <a href={href} target="_blank" rel="noreferrer" className={className}>
      {children}
    </a>
  );
};

export default ExternalLink;
