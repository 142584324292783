import React from 'react';
import { Outlet } from 'react-router-dom';
import ErrorMsg from '../ErrorMsg';

const BaseLayout: React.FC = () => {
  return (
    <>
      <Outlet />
      <ErrorMsg />
    </>
  );
};

export default BaseLayout;
