export default {
  common: {
    colon: '：',
    close: '閉じる',
    jpy: 'JPY',
    error: 'ERROR',
    contact: 'お問い合わせ',
    delete: '削除',
    cancel: 'キャンセル'
  },
  email: {
    subject: 'LINK-PAY: 注文（{{orderId}}）について',
    orderId: '注文番号: {{orderId}}',
    contact: 'ご担当者、\n<お問い合わせ内容を下記にご記入ください。>'
  },
  account: {
    title: 'お支払い方法管理',
    caption: '登録済みのクレジットカード',
    tips: '※クレジットカードの追加は最大5枚まで可能です。',
    deleteCardSuccess: 'クレジットカード削除しました。',
    unregistered: '登録済みのクレジットカードはありません。',
    deleteTitle: '削除の確認',
    deleteTips: 'クレジットカードを削除しますか？'
  },
  order: {
    paymentNumber: '決済番号',
    totalPrice: '総額',
    useAvailableCard: '登録済みのクレジットカードを利用',
    useNewCard: '新しいクレジットカードを利用',
    acceptedPayment: 'ご利用可能なお支払い方法',
    email: 'メールアドレス',
    deadline: '支払い期限',
    pay: '{{amount}} を支払う',
    error: '購入処理中にエラーが発生しました！エラーコード：{{code}}',
    secure: '3D Secure',
    secureMessage:
      '当社のサービスを利用するには、<a>本人認証サービス「3Dセキュア2.0（EMV 3Dセキュア）」</a> が必要です。',
    saveCardInfo: 'クレジットカード情報を保存する',
    creditCardVerified: 'クレジットカードの確認完了いたしました。',
    incompleteOrder: 'ご注文はまだ完了しておりません。',
    clickToConfirm: '必ず下の確定ボタンを押してください。',
    confirmOrder: 'ご注文を確定する',
    countdown: '（ {{count}} 秒）',
    paymentError: '決済処理中にエラーが発生しました。',
    confirmDeadline:
      '注文情報が正しいか、\n または有効期限を確認してください。',
    orderSuccess: '注文が確定致しました',
    orderSuccessSubText: 'ご利用いただきありがとうございます',
    contact:
      '問題が解決できない場合は、\n「お問い合わせ」よりお問い合わせください。',
    backShop: '販売ページへ戻る',
    paymentDetails: '支払い詳細',
    paymentLogs: '支払い記録',
    print: 'このページ印刷する',
    creditCard: 'クレジットカード',
    addCard: 'カードを追加',
    paymentMethod: 'お支払い方法'
  },
  product: {
    name: '種類',
    price: '単価',
    quantity: '数量',
    total: '金額'
  },
  transaction: {
    timestamp: '時間',
    status: '状態',
    message: '詳細情報',
    charge: '支払い'
  },
  card: {
    cardNumber: 'カード番号',
    cardExpiry: '有効期限',
    cardCvc: 'セキュリティコード',
    cardName: 'カードに表示される名前'
  },
  footer: {
    terms: '利用規約',
    privacy: 'プライバシーポリシー',
    faq: 'よくある質問'
  },
  error: {
    invalidCardNumber: '無効なカード番号です。',
    invalidCardType: '無効なカードタイプです。',
    invalidCardExpiry: '有効期限をご確認下さい',
    pastCardExpiry: 'カードの有効期限が過ぎてます。',
    invalidCardCvc: '無効なセキュリティコードです。',
    invalidCardName: '名前は半角英字で入力してください。'
  }
};
